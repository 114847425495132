import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Heading } from '../../../../components';
import Summary from '../../components/summary';
import SubscoreCard from '../../components/subscore-card';
import Hero from '../../components/hero';
import useResults from '../../hooks/use-results';
import getPath from '../../../../utils/path';

function getScoreCategory(score, t) {
  if (score < 43) {
    return [0, t('results.category.struggling.title'), 'results.category.struggling.content'];
  }

  if (score >= 43 && score < 57) {
    return [1, t('results.category.languishing.title'), 'results.category.languishing.content'];
  }

  if (score >= 57 && score < 68) {
    return [2, t('results.category.getting_by.title'), 'results.category.getting_by.content'];
  }

  return [3, t('results.category.flourishing.title'), 'results.category.flourishing.content'];
}

function Results() {
  const { results } = useResults();
  const { lang } = useParams();
  const { t } = useTranslation();
  const [scoreCategory, label, desc] = getScoreCategory(results?.MHI, t);
  const goodScores = [];
  const supportScores = [];

  const cardCSOM = (
    <SubscoreCard
      key="csom"
      type="csom"
      title={t('screening.global.section.csom.title')}
      subtitle={t('screening.global.section.csom.subtitle')}
      cta={t('screening.global.section.csom.cta')}
      score={results?.CSOM}
      link="csom"
      icon="/bg-hero-csom-1.png"
    />
  );

  const cardSB = (
    <SubscoreCard
      key="sb"
      type="sb"
      title={t('screening.global.section.sb.title')}
      subtitle={t('screening.global.section.sb.subtitle')}
      cta={t('screening.global.section.sb.cta')}
      score={results?.SB}
      link="sb"
      icon="/bg-hero-sb-1.png"
    />
  );

  const cardLS = (
    <SubscoreCard
      key="ls"
      type="ls"
      title={t('screening.global.section.ls.title')}
      subtitle={t('screening.global.section.ls.subtitle')}
      cta={t('screening.global.section.ls.cta')}
      score={results?.SCB_2}
      link="ls"
      icon="/bg-hero-ls-1.png"
    />
  );

  if (results?.CSOM > 60) {
    goodScores.push(cardCSOM);
  } else {
    supportScores.push(cardCSOM);
  }

  if (results?.SB > 60) {
    goodScores.push(cardSB);
  } else {
    supportScores.push(cardSB);
  }

  if (results?.SCB_2 > 60) {
    goodScores.push(cardLS);
  } else {
    supportScores.push(cardLS);
  }

  return (
    <div className="w-full mx-auto">
      <div className="w-full md:bg-gray-200 md:h-[370px]">
        <Hero />
      </div>
      <div className="container mx-auto px-4 flex flex-col mt-28 max-w-[736px] lg:pb-[200px] md:mt-[-100px]">
        <Summary
          title={t('results.title')}
          score={results?.MHI}
          scoreCategory={scoreCategory}
          label={label}
          desc={desc}
          explain="results.mhi.explain.content"
          cta={t('results.mhi.explain.cta')}
        />
        <div className="flex flex-col gap-12 mt-12">
          <ButtonGroup>
            <Button type="link" to={getPath(lang, '/safeguarding')} variant="outline-primary" id="get_help_now">
              {t('consent.get_help_now')}
            </Button>
          </ButtonGroup>
          {goodScores.length > 0 && (
            <div className="flex flex-col gap-4">
              <Heading variant="h2">{t('results.good')}</Heading>
              <div className="flex flex-col flex-wrap gap-4 md:flex-row">
                {goodScores}
              </div>
            </div>
          )}
          {supportScores.length > 0 && (
            <div className="flex flex-col gap-4">
              <Heading variant="h2">{t('results.support')}</Heading>
              <div className="flex flex-col flex-wrap gap-4 md:flex-row md:flex-wrap">
                {supportScores}
              </div>
            </div>
          )}
        </div>
        <ButtonGroup>
          <Button type="link" to={getPath(lang, '/recommendations')} variant="primary" id="what_you_can_do_next" className="mt-8 md:mt-12">
            {t('results.do_next')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default Results;
