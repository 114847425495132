// Utility function to get the correct URL path taking the currently
// selected language into account.
function getPath(lang, suffix) {
  let path = suffix;

  if (typeof lang === 'string' && lang !== '') {
    path = `/${lang}${suffix}`;
  }

  return path;
}

export default getPath;
