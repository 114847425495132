import React, { useEffect } from 'react';
import {
  Outlet,
  ScrollRestoration,
  useMatches,
  useParams,
} from 'react-router-dom';
import { Footer, Header } from '../../components';
import Holding from './components/holding';
import useStore from '../../store';
import useTTL from '../../hooks/use-ttl';
import useReport from '../../hooks/use-report';

const selector = (state) => [state.session, state.setSession];

const Config = {
  COMING_SOON: import.meta.env.VITE_COMING_SOON,
};

// Wrapper component for all routes.
function Root() {
  const [session, setSession] = useStore(selector);

  useTTL();
  useEffect(() => {
    if (session === null) {
      const sessionId = window.crypto.randomUUID();

      setSession(sessionId);
    }
  }, []);

  // Start reporting - this monitors the URL to automatically report page loads.
  // Configure page load reporting.
  const { lang } = useParams();
  const matches = useMatches();
  const { Report, report } = useReport();

  useEffect(() => {
    const match = matches[matches.length - 1];
    const key = match?.handle?.key?.(match?.params ?? {});

    if (key) {
      report({
        action: Report.Actions.PAGE_LOAD,
      });
    }
  }, [matches, lang]);

  return (
    <div className="flex flex-col min-h-screen">
      <ScrollRestoration />
      <Header />
      <div className="flex flex-1">
        {Config.COMING_SOON ? <Holding /> : <Outlet />}
      </div>
      {Config.COMING_SOON ? null : <Footer />}
    </div>
  );
}

export default Root;
