import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useReport from '../../hooks/use-report';
import Link from '../link';

const commonStyles = 'block uppercase font-semibold text-center text-sm transition focus:outline-none focus-visible:outline-none';
const variantStyles = {
  primary: 'bg-axa-blue-500 hover:bg-axa-blue-600 text-white active:shadow-[inset_5em_5em_5em_5em_rgba(255,255,255,0.1),0_0_0_0.2rem_rgba(0,0,143,0.5)] focus:shadow-[inset_5em_5em_5em_5em_rgba(255,255,255,0.1),0_0_0_0.2rem_rgba(0,0,143,0.5)]',
  'outline-primary': 'text-axa-blue-500 shadow-[inset_0_0_0_0.1em_#00008f] hover:bg-axa-blue-500 hover:text-white',
  secondary: 'bg-axa-sienna-400 hover:bg-axa-sienna-200 shadow-[inset_0_-3px_0_rgba(0,0,0,0.2)] text-white',
};
const sizeStyles = {
  default: 'px-6 py-4',
  sm: 'px-4 py-3',
};

/* eslint-disable react/button-has-type */
const Button = forwardRef(({
  children,
  className,
  onClick,
  id,
  type,
  variant = 'default',
  size = 'default',
  ...props
}, ref) => {
  const { report } = useReport();
  const classes = classnames(commonStyles, variantStyles[variant], sizeStyles[size], className);
  const fireReport = async (data) => report(data);
  const handleClick = async (e, isExternal) => {
    if (id) {
      const action = {
        action: `click[${id}]`,
      };

      // If the link is external we need to wait for a response from the
      // reporting endpoint to ensure it's tracked before navigation.
      // Otherwise, it's just fire and forget.
      if (isExternal) {
        await fireReport(action);
      } else {
        fireReport(action);
      }
    }

    onClick?.(e);
  };

  if (type === 'link') {
    return (
      <Link className={classes} onClick={handleClick} id={id} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button className={classes} type={type} ref={ref} onClick={handleClick} id={id} {...props}>
      {children}
    </button>
  );
});

Button.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'default',
    'primary',
    'outline-primary',
    'secondary',
  ]),
  size: PropTypes.oneOf([
    'default',
    'sm',
  ]),
};

export default Button;
