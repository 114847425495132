import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import getPath from '../../../../utils/path';
import Config from '../../config';

function useScreening({ responses, sectionIndex, questionIndex }) {
  const navigate = useNavigate();
  const { lang } = useParams();
  const si = parseInt(sectionIndex, 10);
  const qi = parseInt(questionIndex, 10);
  let validResponses = true;

  for (let i = 0; i < si; i++) {
    const section = Config.Screening.Global.Sections[i];
    const { questions } = section;
    const limit = i < si - 1 ? questions.length : qi - 1;

    for (let j = 0; j < limit; j++) {
      const question = questions[j];
      const response = responses[question.promptId];

      if (response === undefined) {
        validResponses = false;
      }
    }
  }

  useEffect(() => {
    if (!validResponses) {
      navigate(getPath(lang, '/?error=invalid_request_data'));
    }
  }, [validResponses]);

  const section = Config.Screening.Global.Sections[sectionIndex - 1];
  const question = section.questions[questionIndex - 1];

  return {
    sections: Config.Screening.Global.Sections,
    sectionIndex: si,
    questionIndex: qi,
    section,
    question,
  };
}

export default useScreening;
