import React from 'react';

function Header() {
  return (
    <nav className="flex h-[71px] bg-white border-b border-gray-100 shadow-[inset_0_-1px_#f0f0f0] sticky top-0 z-40">
      <a
        href="https://www.axa.com/"
        className="bg-[url('/axa.svg')] bg-center bg-no-repeat min-w-[6rem] min-h-[4.5rem] block"
      />
    </nav>
  );
}

export default Header;
