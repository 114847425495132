import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Link,
} from '../../../../components';
import IconNavigateNext from '../../../../assets/icon-navigate-next.svg?react';
import PowerBar from '../powerbar';

const bounds = {
  csom: [[0, 20], [21, 60], [61, 100]],
  sb: [[0, 40], [41, 60], [61, 100]],
  ls: [[0, 20], [21, 60], [61, 100]],
};

function SubscoreCard({
  type,
  title,
  subtitle,
  cta,
  link,
  score,
  icon,
}) {
  const typeBounds = bounds[type];
  const bound = typeBounds.findIndex(([x, y]) => score >= x && score <= y);

  return (
    <Card className="w-full md:w-[344px]">
      <CardBody className="flex flex-grow gap-4">
        <div>
          <img src={icon} alt="" className="w-auto max-w-[61px] h-[61px] rounded-full" />
        </div>
        <div className="flex flex-col gap-2">
          <Heading variant="h3">
            {title}
          </Heading>
          <p className="text-sm">
            {subtitle}
          </p>
          <div className="flex gap-2 items-center">
            <p className="font-semibold text-axa-blue-100">{score}</p>
            <PowerBar active={bound} score={score} variant="mini" />
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <Link to={link} className="text-axa-blue-500 font-semibold flex justify-between w-full">
          {cta}
          <IconNavigateNext className="inline fill-axa-blue-500" />
        </Link>
      </CardFooter>
    </Card>
  );
}

SubscoreCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  icon: PropTypes.string,
};

export default SubscoreCard;
