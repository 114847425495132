import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '../../../../components';
import useReport from '../../../../hooks/use-report';
import ResponseCard from '../../components/response-card';
import QuestionCard from '../../components/question-card';
import TimeframeModal from '../../components/timeframe-modal';
import useStore from '../../../../store';
import getPath from '../../../../utils/path';
import IconNavigateBefore from '../../../../assets/icon-navigate-before.svg?react';
import useScreening from '../../hooks/use-screening';

const selector = (state) => [state.setResponse, state.responses];
const moveOn = (navigate, path, opts) => setTimeout(() => {
  navigate(path, opts);
}, 300);

function Question() {
  const [setResponse, responses] = useStore(selector);
  const { Report, report } = useReport();
  const [timeframeModalOpen, setTimeframeModalOpen] = useState(false);
  const cardRefs = useRef([]);
  const { t } = useTranslation();
  const { sectionIndex: sectionIndexStr, questionIndex: questionIndexStr, lang } = useParams();
  const navigate = useNavigate();
  const {
    sectionIndex,
    questionIndex,
    sections,
    section,
    question,
  } = useScreening({
    responses,
    sectionIndex: sectionIndexStr,
    questionIndex: questionIndexStr,
  });
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const handleResponse = (response) => {
    const nextQuestion = section.questions[questionIndex];
    const nextSection = sections[sectionIndex];

    setResponse(question.promptId, response);
    report({
      action: Report.Actions.ANSWER,
    });

    if (nextQuestion !== undefined) {
      return moveOn(navigate, getPath(lang, `/assessment/section/${sectionIndex}/q/${questionIndex + 1}`), {
        preventScrollReset: true,
      });
    }

    if (nextSection !== undefined) {
      return moveOn(navigate, getPath(lang, `/assessment/section/${sectionIndex + 1}`));
    }

    return moveOn(navigate, getPath(lang, '/assessment/complete'));
  };

  const toggleTimeframeModal = () => {
    setTimeframeModalOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!timeframeModalOpen) {
      if (e.keyCode === 38 && focusedIndex < 0) {
        e.preventDefault();
        setFocusedIndex(0);
        cardRefs.current[0].focus();
      } else if (e.keyCode === 38 && focusedIndex > 0) {
        e.preventDefault();
        setFocusedIndex((index) => index - 1);
        cardRefs.current[focusedIndex - 1].focus();
      } else if (e.keyCode === 40 && focusedIndex < cardRefs.current.length - 1) {
        e.preventDefault();
        setFocusedIndex((index) => index + 1);
        cardRefs.current[focusedIndex + 1].focus();
      }
    }
  };

  const handleFocus = (index) => {
    setFocusedIndex(index);
  };

  // Handle global key events to manage focus state.
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Reset focused response card when the question changes.
  useEffect(() => {
    if (question.timeframeModal !== undefined) {
      setTimeframeModalOpen(true);
    }

    const selection = responses[question.promptId];
    let index = 0;

    if (selection) {
      index = question.responses.findIndex((response) => response.id === selection);

      cardRefs.current[index].focus();
    } else {
      setFocusedIndex(-1);
    }
  }, [question]);

  const responseCards = question.responses.map((response, i) => (
    <ResponseCard
      key={`${question.promptId}#${response.id}`}
      id={response.id}
      content={t(response.content)}
      promptId={question.promptId}
      handleResponse={handleResponse}
      selected={responses[question.promptId] === response.id}
      onFocus={() => handleFocus(i)}
      ref={(el) => {
        cardRefs.current[i] = el;
      }}
      tabIndex="0"
    />
  ));

  return (
    <div className="relative flex flex-1 flex-col">
      <TimeframeModal
        isOpen={timeframeModalOpen}
        toggle={toggleTimeframeModal}
        content={question.timeframeModal}
      />
      <div
        className={`bg-cover bg-no-repeat h-[250px] w-full bg-center lg:absolute lg:-z-10 lg:h-full ${section.backgroundStyles || ''}`}
        style={{ backgroundImage: `url(${section.background})` }}
      />
      <div className="container mx-auto px-4 flex flex-col mt-[-220px] lg:my-20 lg:mt-8 lg:px-12">
        <Heading variant="h3" className="mb-[30px] text-center !text-axa-blue-500">{t(section.title)}</Heading>
        <div className="flex flex-col gap-8 md:gap-12">
          <div className="w-full lg:max-w-[964px] mx-auto lg:bg-blue-grey lg:px-40 lg:rounded-2xl lg:pt-2 lg:pb-12 lg:shadow-[0px_4px_8px_0px_rgba(0,0,0,0.20)]">
            <QuestionCard
              question={t(question.question)}
              image={t(question.image)}
              prompt={question.prompt}
              timeframe={t(question.timeframe)}
              total={section.questions.length}
              current={parseInt(questionIndex, 10)}
            />
            {question.promptId === 'A1' ? (
              <div className="flex gap-2 mt-4">
                {responseCards}
              </div>
            ) : (
              <div className="flex flex-col gap-2 mt-4 mx-auto lg:max-w-[512px] lg:gap-3">
                {responseCards}
              </div>
            )}
            <div className="flex justify-center mt-24 lg:mt-12">
              <Button
                variant="default"
                type="button"
                onClick={(e) => { e.preventDefault(); navigate(-1); }}
                size="sm"
                className="flex items-center gap-4 text-axa-blue-500"
                tabIndex="-1"
                id="back"
              >
                <IconNavigateBefore className="inline fill-axa-blue-500" />
                {t('back')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
