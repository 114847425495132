import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';
import {
  Button,
  ButtonGroup,
  Heading,
  HeroLanding,
  Progress,
} from '../../../../components';
import { LayoutLanding } from '../../../../layouts';
import IconNavigateBefore from '../../../../assets/icon-navigate-before.svg?react';
import Config from '../../config';

const selector = (state) => [state.responses];

function Screening() {
  const navigate = useNavigate();
  const [responses] = useStore(selector);
  const { t } = useTranslation();
  const Screening = Config.Screening.Global;

  return (
    <LayoutLanding hero={<HeroLanding />}>
      <Heading variant="h1" className="text-center">{t(Screening.title)}</Heading>
      <div className="md:w-[692px] flex self-center">
        <p>{t(Screening.subtitle)}</p>
      </div>
      <div className="flex flex-col gap-3 max-w-[692px] w-full mx-auto">
        {Screening.Sections.map((section) => {
          const progress = section.questions.reduce((total, question) => {
            if (responses[question.promptId] !== undefined) {
              return total + 1;
            }

            return total;
          }, 0);

          return (
            <div key={section.id} className="px-5 py-6 shadow-sm rounded bg-white">
              <div className="flex justify-between items-center">
                <Heading variant="h3">{t(section.title)}</Heading>
                <p className="text-xs">{progress} / {section.questions.length}</p>
              </div>
              <Progress total={section.questions.length} current={progress} className="mt-3" />
            </div>
          );
        })}
      </div>
      <ButtonGroup row>
        <Button
          variant="default"
          type="button"
          onClick={(e) => { e.preventDefault(); navigate(-1); }}
          size="sm"
          className="flex items-center gap-4 text-axa-blue-500"
          tabIndex="-1"
          id="back"
        >
          <IconNavigateBefore className="inline fill-axa-blue-500" />
          {t('back')}
        </Button>
        <Button
          type="link"
          to="section/1"
          variant="primary"
          id="continue"
        >
          {t('continue')}
        </Button>
      </ButtonGroup>
    </LayoutLanding>
  );
}

export default Screening;
