import React from 'react';
import classnames from 'classnames';

const commonClasses = ['px-4', 'py-3', 'min-w-32', 'text-sm', 'font-semibold', 'uppercase', 'transition-all'];
const activeClasses = ['bg-axa-sienna-400', 'text-white'];
const inactiveClasses = ['bg-white', 'hover:bg-axa-sienna-100', 'hover:text-white', 'text-axa-sienna-400', 'shadow-[inset_0_0_0_0.1em_#d24723]'];

function Toggle({ items, active = 0, onChange }) {
  return items.map((item, i) => {
    const classes = classnames(commonClasses, active === i ? activeClasses : inactiveClasses);

    return (
      <button key={item} type="button" onClick={() => onChange(i)} className={classes}>{item}</button>
    );
  });
}

export default Toggle;
