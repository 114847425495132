import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import httpBackend from 'i18next-http-backend';

i18n
  .use(httpBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-GB': ['en'],
      'fr-BE': ['fr', 'en'],
      'nl-BE': ['nl', 'en'],
      'de-CH': ['de', 'en'],
      'fr-CH': ['fr', 'en'],
      'it-CH': ['it', 'en'],
      hk: ['zh-Hans-HK'],
      default: ['en'],
    },
    detection: {
      order: ['path'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['a', 'br', 'strong', 'i', 'b', 'small'],
    },
  });

export default i18n;
