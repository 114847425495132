import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const store = (set) => ({
  session: null,
  setSession: (id) => set((state) => {
    state.session = id;
  }),
  responses: {},
  setResponse: (id, response) => set((state) => {
    state.responses[id] = response;
  }),
  results: null,
  setResults: (results) => set((state) => {
    state.results = results;
  }),
});

const immutableStore = immer(store);
const useStore = create(immutableStore);

export default useStore;
