import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  HeroLanding,
  Type,
} from '../../components';
import { LayoutLanding } from '../../layouts';
import useTranslate from '../../hooks/use-translate';
import LanguagesModal from './components/languages-modal';

function Landing() {
  const { t, i18n } = useTranslation();
  const { trans } = useTranslate([
    'welcome.intro',
  ]);
  const [languagesModalIsOpen, setLanguagesModalIsOpen] = useState(false);

  const toggleLanguagesModal = () => {
    setLanguagesModalIsOpen((isOpen) => !isOpen);
  };

  return (
    <LayoutLanding hero={<HeroLanding />}>
      <LanguagesModal
        active={i18n.language}
        isOpen={languagesModalIsOpen}
        toggle={toggleLanguagesModal}
      />
      <div className="flex flex-col gap-8 mx-auto md:gap-16 lg:gap-12 lg:max-w-[500px]">
        <h1 className="text-2xl font-bold text-center">
          <Trans i18nKey="welcome.title" />
        </h1>
        <Type variant="lead">{trans['welcome.intro']}</Type>
        <ButtonGroup>
          <Button type="link" to="consent" variant="primary" id="start">{t('welcome.cta')}</Button>
          <Button
            type="button"
            variant="outline-primary"
            onClick={toggleLanguagesModal}
            id="change_language"
          >
            {t('welcome.change_language')}
          </Button>
        </ButtonGroup>
      </div>
    </LayoutLanding>
  );
}

export default Landing;
