import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useTranslate from '../../../../hooks/use-translate';
import {
  BackButton,
  Button,
  ButtonGroup,
  Heading,
  Type,
} from '../../../../components';
import IconNavigateBefore from '../../../../assets/icon-navigate-before.svg?react';
import IconCall from '../../../../assets/icon-call.svg?react';
import IconTalk from '../../../../assets/icon-talk.svg?react';

const Config = {
  default: [
    {
      id: 'list-global',
      type: 'ul',
      content: [
        {
          id: 'list-item-global',
          type: 'list-item-icon',
          icon: '/icon-global.png',
          alt: 'Image of the globe',
          title: 'safeguarding.global.title.global',
          content: [
            'safeguarding.global.emergency.global',
          ],
        },
        {
          id: 'list-item-be',
          type: 'list-item-icon',
          icon: '/icon-flag-be.png',
          alt: 'Flag of Belgium',
          title: 'safeguarding.global.title.be',
          content: [
            'safeguarding.global.emergency.be',
            'safeguarding.global.helpline.be',
          ],
        },
        {
          id: 'list-item-fr',
          type: 'list-item-icon',
          icon: '/icon-flag-fr.png',
          alt: 'Flag of France',
          title: 'safeguarding.global.title.fr',
          content: [
            'safeguarding.global.emergency.fr',
            'safeguarding.global.helpline.fr',
          ],
        },
        {
          id: 'list-item-de',
          type: 'list-item-icon',
          icon: '/icon-flag-de.png',
          alt: 'Flag of Germany',
          title: 'safeguarding.global.title.de',
          content: [
            'safeguarding.global.emergency.de',
            'safeguarding.global.helpline.de',
          ],
        },
        {
          id: 'list-item-hk',
          type: 'list-item-icon',
          icon: '/icon-flag-hk.png',
          alt: 'Flag of Hong Kong',
          title: 'safeguarding.global.title.hk',
          content: [
            'safeguarding.global.emergency.hk',
            'safeguarding.global.helpline.hk',
          ],
        },
        {
          id: 'list-item-it',
          type: 'list-item-icon',
          icon: '/icon-flag-it.png',
          alt: 'Flag of Italy',
          title: 'safeguarding.global.title.it',
          content: [
            'safeguarding.global.emergency.it',
            'safeguarding.global.helpline.it',
          ],
        },
        {
          id: 'list-item-jp',
          type: 'list-item-icon',
          icon: '/icon-flag-jp.png',
          alt: 'Flag of Japan',
          title: 'safeguarding.global.title.jp',
          content: [
            'safeguarding.global.emergency.jp',
            'safeguarding.global.helpline.jp',
          ],
        },
        {
          id: 'list-item-mx',
          type: 'list-item-icon',
          icon: '/icon-flag-mx.png',
          alt: 'Flag of Mexico',
          title: 'safeguarding.global.title.mx',
          content: [
            'safeguarding.global.emergency.mx',
            'safeguarding.global.helpline.mx',
          ],
        },
        {
          id: 'list-item-ph',
          type: 'list-item-icon',
          icon: '/icon-flag-ph.png',
          alt: 'Flag of Philippines',
          title: 'safeguarding.global.title.ph',
          content: [
            'safeguarding.global.emergency.ph',
            'safeguarding.global.helpline.ph',
          ],
        },
        {
          id: 'list-item-es',
          type: 'list-item-icon',
          icon: '/icon-flag-es.png',
          alt: 'Flag of Spain',
          title: 'safeguarding.global.title.es',
          content: [
            'safeguarding.global.emergency.es',
          ],
        },
        {
          id: 'list-item-ch',
          type: 'list-item-icon',
          icon: '/icon-flag-ch.png',
          alt: 'Flag of Switzerland',
          title: 'safeguarding.global.title.ch',
          content: [
            'safeguarding.global.emergency.ch',
            'safeguarding.global.helpline.ch',
          ],
        },
        {
          id: 'list-item-th',
          type: 'list-item-icon',
          icon: '/icon-flag-th.png',
          alt: 'Flag of Thailand',
          title: 'safeguarding.global.title.th',
          content: [
            'safeguarding.global.emergency.th',
            'safeguarding.global.helpline.th',
          ],
        },
        {
          id: 'list-item-tr',
          type: 'list-item-icon',
          icon: '/icon-flag-tr.png',
          alt: 'Flag of Turkey',
          title: 'safeguarding.global.title.tr',
          content: [
            'safeguarding.global.emergency.tr',
          ],
        },
        {
          id: 'list-item-uk',
          type: 'list-item-icon',
          icon: '/icon-flag-uk.png',
          alt: 'Flag of the United Kingdom',
          title: 'safeguarding.global.title.uk',
          content: [
            'safeguarding.global.emergency.uk',
            'safeguarding.global.helpline.uk',
          ],
        },
      ],
    },
  ],
  fr: [
    {
      id: 'card-group-fr',
      type: 'card-group',
      content: [
        {
          id: 'card-fr',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.fr',
          ],
        },
        {
          id: 'card-fr-2',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.fr',
          ],
        },
      ],
    },
  ],
  de: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '08001110111',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.de',
          ],
        },
        {
          id: 'card-uk-2',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.de',
          ],
        },
      ],
    },
  ],
  'fr-BE': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '107',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.be',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.be',
          ],
        },
      ],
    },
  ],
  'nl-BE': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '106',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.be',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.be',
          ],
        },
      ],
    },
  ],
  hk: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '999',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.hk',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.hk',
          ],
        },
      ],
    },
  ],
  'zh-Hans-HK': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '999',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.hk',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.hk',
          ],
        },
      ],
    },
  ],
  it: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.it',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.it',
          ],
        },
      ],
    },
  ],
  ja: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '0120783556',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.jp',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.jp',
          ],
        },
      ],
    },
  ],
  'es-MX': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '8009112000',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.mx',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.mx',
          ],
        },
      ],
    },
  ],
  es: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '112',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.es',
          ],
        },
      ],
    },
  ],
  'de-CH': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '143',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.ch',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.ch',
          ],
        },
      ],
    },
  ],
  'fr-CH': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '143',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.ch',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.ch',
          ],
        },
      ],
    },
  ],
  'it-CH': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '143',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.ch',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.ch',
          ],
        },
      ],
    },
  ],
  th: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '1323',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.th',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.th',
          ],
        },
      ],
    },
  ],
  tr: [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '112',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.tr',
          ],
        },
      ],
    },
  ],
  'en-GB': [
    {
      id: 'card-group-uk',
      type: 'card-group',
      call: '116123',
      content: [
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.uk',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.uk',
          ],
        },
      ],
    },
  ],
  'en-PH': [
    {
      id: 'card-group-ph',
      type: 'card-group',
      call: '180018881553',
      content: [
        {
          id: 'card-ph',
          type: 'card',
          icon: 'icon-call',
          content: [
            'safeguarding.global.emergency.ph',
          ],
        },
        {
          id: 'card-uk',
          type: 'card',
          icon: 'icon-talk',
          content: [
            'safeguarding.global.helpline.ph',
          ],
        },
      ],
    },
  ],
};

function ContentRenderer({ content }) {
  const { t } = useTranslation();

  return content.map((item) => {
    switch (item.type) {
      case 'ul':
        return (
          <ul key={item.id} className="flex flex-col gap-5 md:flex-row md:flex-wrap md:gap-6">
            <ContentRenderer content={item.content} />
          </ul>
        );

      // NOTE: The calculation for width is necessary to achieve a 2-column
      // layout when using flexbox gap because flex basis can't take the gap
      // into account. The 1.5rem value is the size of `gap-6` so if the gap
      // size were to change this calculation would need to change too.
      case 'list-item-icon':
        return (
          <li key={item.id} className="flex gap-4 md:w-[calc(50%-1.5rem)]">
            <img src={item.icon} alt={item.alt} className="w-[73px] h-[73px] rounded-full object-cover shadow" />
            <div className="flex flex-col min-w-1">
              <p className="font-bold">{t(item.title)}</p>
              <ContentRenderer content={item.content} />
            </div>
          </li>
        );
      case 'card-group':
        return (
          <div className="flex flex-col md:flex-row md:flex-wrap gap-4 items-stretch">
            <ContentRenderer content={item.content} />
          </div>
        );
      case 'card': {
        let icon;

        switch (item.icon) {
          case 'icon-call':
            icon = <IconCall />;
            break;
          case 'icon-talk':
            icon = <IconTalk />;
            break;
          default:
            icon = null;
        }

        return (
          <div className="md:bg-white md:p-6 flex-1 h-full">
            {icon !== null && <div className="hidden md:block">{icon}</div>}
            <ContentRenderer content={item.content} />
          </div>
        );
      }
      default:
        return (
          <p key={item} className="mt-2 first:mt-0 break-words">
            <Trans
              i18nKey={item}
              components={{
                Link: <a className="text-axa-blue-500 underline" target="_blank" />,
              }}
            />
          </p>
        );
    }
  });
}

function Safeguarding() {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { trans, t } = useTranslate([
    'safeguarding.lead',
  ]);
  const content = Config[lang] || Config.default;

  return (
    <div className="container mx-auto px-4 pt-4 flex flex-col md:mb-12">
      <div className="flex justify-between md:justify-normal">
        <div className="bg-[url('/bg-hero-safeguarding-1.png')] bg-no-repeat bg-cover h-[170px] w-[241px] md:w-[534px] md:h-[378px] md:mx-auto" />
      </div>
      <Heading variant="h1" className="md:text-center">{t('safeguarding.title')}</Heading>
      <Type variant="lead" className="!text-axa-ocean-300 mt-5 md:text-center md:max-w-[361px] mx-auto mb-6 md:mb-12">
        {trans['safeguarding.lead']}
      </Type>
      <ContentRenderer content={content} />
      <div className="mt-5 md:bg-white md:p-6">
        <Heading variant="h2">{t('safeguarding.doctor.title')}</Heading>
        <p className="mt-4">{t('safeguarding.doctor.content')}</p>
      </div>
      {content?.[0]?.call && (
        <ButtonGroup className="mt-8 lg:mt-12">
          <Button
            type="link"
            variant="primary"
            to={`tel://${content[0].call}`}
          >
            {t('safeguarding.call')}
          </Button>
        </ButtonGroup>
      )}
      <div className="flex justify-center mt-12">
        <BackButton />
      </div>
    </div>
  );
}

export default Safeguarding;
