import { redirect } from 'react-router-dom';
import useStore from '../../../../store';
import getPath from '../../../../utils/path';
import API from '../../../../services/api';

async function action({ params }) {
  const { assessmentId } = params;
  const url = assessmentId !== undefined ? `/assessment/${assessmentId}` : '/assessment/dmhi';

  // Submit the gathered responses and wait for results to come back from the
  // API.
  const { responses, setResults } = useStore.getState();
  const res = await API.post(url, {
    body: {
      responses,
    },
  });

  // Update global store with the results returned by the API.
  setResults(res?.data?.score);

  // Redirect to the results page.
  return redirect(getPath(params.lang, '/results'));
}

export default action;
