import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Heading, Modal } from '../../../../components';

import ImgIconFlagUK from '../../../../assets/icon-flag-uk.png';
import ImgIconFlagFR from '../../../../assets/icon-flag-fr.png';
import ImgIconFlagJP from '../../../../assets/icon-flag-jp.png';
import ImgIconFlagTR from '../../../../assets/icon-flag-tr.png';
import ImgIconFlagBE from '../../../../assets/icon-flag-be.png';
import ImgIconFlagDE from '../../../../assets/icon-flag-de.png';
import ImgIconFlagIT from '../../../../assets/icon-flag-it.png';
import ImgIconFlagHK from '../../../../assets/icon-flag-hk.png';
import ImgIconFlagES from '../../../../assets/icon-flag-es.png';
import ImgIconFlagMX from '../../../../assets/icon-flag-mx.png';
import ImgIconFlagTH from '../../../../assets/icon-flag-th.png';
import ImgIconFlagCH from '../../../../assets/icon-flag-ch.png';
import ImgIconFlagPH from '../../../../assets/icon-flag-ph.png';
import ImgIconGlobal from '../../../../assets/icon-global.png';

const languages = [
  {
    name: 'UK (English)',
    icon: ImgIconFlagUK,
    code: 'en-GB',
    url: '/en-GB',
  },
  {
    name: 'France (Français)',
    icon: ImgIconFlagFR,
    code: 'fr',
    url: '/fr',
  },
  {
    name: '日本 (Japan)',
    icon: ImgIconFlagJP,
    code: 'ja',
    url: '/ja',
  },
  {
    name: 'Türkiye (Türkçe)',
    icon: ImgIconFlagTR,
    code: 'tr',
    url: '/tr',
  },
  {
    name: 'België (Nederlands)',
    icon: ImgIconFlagBE,
    code: 'nl-BE',
    url: '/nl-BE',
  },
  {
    name: 'Belgique (Français)',
    icon: ImgIconFlagBE,
    code: 'fr-BE',
    url: '/fr-BE',
  },
  {
    name: 'Deutschland (Deutsch)',
    icon: ImgIconFlagDE,
    code: 'de',
    url: '/de',
  },
  {
    name: 'Italia (Italiano)',
    icon: ImgIconFlagIT,
    code: 'it',
    url: '/it',
  },
  {
    name: '香港 (Hong Kong)',
    icon: ImgIconFlagHK,
    code: 'hk',
    url: '/hk',
  },
  {
    name: 'España (Español)',
    icon: ImgIconFlagES,
    code: 'es',
    url: '/es',
  },
  {
    name: 'México(Español)',
    icon: ImgIconFlagMX,
    code: 'es-MX',
    url: '/es-MX',
  },
  {
    name: 'ประเทศไทย (Thailand)',
    icon: ImgIconFlagTH,
    code: 'th',
    url: '/th',
  },
  {
    name: 'Suisse (Français)',
    icon: ImgIconFlagCH,
    code: 'fr-CH',
    url: '/fr-CH',
  },
  {
    name: 'Schweiz (Deutsch)',
    icon: ImgIconFlagCH,
    code: 'de-CH',
    url: '/de-CH',
  },
  {
    name: 'Svizzera (Italiano)',
    icon: ImgIconFlagCH,
    code: 'it-CH',
    url: '/it-CH',
  },
  {
    name: 'Philippines (English)',
    icon: ImgIconFlagPH,
    code: 'en-PH',
    url: '/en-PH',
  },
];

const commonClasses = ['rounded py-3 px-6 flex items-center gap-3 border-gray-400'];

function LanguagesModal({ active, isOpen, toggle }) {
  const { t } = useTranslation();
  const globalClasses = classnames(commonClasses, {
    'bg-blue-grey': active !== 'en',
    'bg-white': active === 'en',
    border: active === 'en',
  });

  return (
    <Modal open={isOpen} onClose={toggle}>
      <Heading variant="h1">{t('welcome.change_language')}</Heading>
      <div className="flex flex-col gap-3 mt-4">
        <ul className="flex flex-col gap-3">
          <li className={globalClasses}>
            <img src={ImgIconGlobal} alt="Globe icon" className="w-[33px] h-[33px] rounded-full object-cover shadow" />
            <a href="/" className="block w-full">Global (English)</a>
          </li>
          {languages.map((lang) => {
            const classes = classnames(commonClasses, {
              'bg-blue-grey': active !== lang.code,
              'bg-white': active === lang.code,
              border: active === lang.code,
            });

            return (
              <li key={lang.name} className={classes}>
                <img src={lang.icon} alt="Icon" className="w-[33px] h-[33px] rounded-full object-cover shadow" />
                <a href={lang.url} className="block w-full">{lang.name}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
}

LanguagesModal.propTypes = {
  active: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default LanguagesModal;
