import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../button';
import IconNavigateBefore from '../../assets/icon-navigate-before.svg?react';
import useTranslate from '../../hooks/use-translate';

function BackButton({ ...props }) {
  const navigate = useNavigate();
  const { t } = useTranslate();

  return (
    <Button
      variant="default"
      type="button"
      onClick={(e) => { e.preventDefault(); navigate(-1); }}
      size="sm"
      className="flex items-center gap-4 text-axa-blue-500"
      tabIndex="-1"
      {...props}
    >
      <IconNavigateBefore className="inline fill-axa-blue-500" />
      {t('back')}
    </Button>
  );
}

export default BackButton;
