import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

function useTrans(keys = []) {
  const { t } = useTranslation();
  const trans = keys.reduce((obj, key) => {
    obj[key] = (
      <Trans
        i18nKey={key}
        components={{
          Link: <a className="text-axa-blue-500 underline" />,
          a: <a className="text-axa-blue-500 underline" />,
          xs: <span className="text-xs" />,
          span: <span />,
        }}
      />
    );

    return obj;
  }, {});

  return {
    trans,
    t,
  };
}

export default useTrans;
