const Config = {
  Screening: {
    Global: {
      title: 'screening.global.title',
      subtitle: 'screening.global.subtitle',
      Sections: [
        {
          title: 'screening.global.section.csom.title',
          content: 'screening.global.section.csom.intro',
          background: '/bg-hero-csom-1.png',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'csom',
          questions: [
            {
              id: 'GRID_QA18b',
              promptId: 'GRID_QA18b:_1',
              question: 'screening.global.question.GRID_QA18b',
              prompt: 'screening.global.question.GRID_QA18b_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA18b.QA18b:_1',
                  content: 'screening.global.response.GRID_QA18b.QA18b_1',
                },
                {
                  id: 'GRID_QA18b.QA18b:_2',
                  content: 'screening.global.response.GRID_QA18b.QA18b_2',
                },
                {
                  id: 'GRID_QA18b.QA18b:_3',
                  content: 'screening.global.response.GRID_QA18b.QA18b_3',
                },
                {
                  id: 'GRID_QA18b.QA18b:_4',
                  content: 'screening.global.response.GRID_QA18b.QA18b_4',
                },
                {
                  id: 'GRID_QA18b.QA18b:_5',
                  content: 'screening.global.response.GRID_QA18b.QA18b_5',
                },
              ],
            },
            {
              id: 'GRID_QA15f',
              promptId: 'GRID_QA15f:_1',
              question: 'screening.global.question.GRID_QA15f',
              prompt: 'screening.global.question.GRID_QA15f_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA15f.QA15f:_1',
                  content: 'screening.global.response.GRID_QA15f.QA15f_1',
                },
                {
                  id: 'GRID_QA15f.QA15f:_2',
                  content: 'screening.global.response.GRID_QA15f.QA15f_2',
                },
                {
                  id: 'GRID_QA15f.QA15f:_3',
                  content: 'screening.global.response.GRID_QA15f.QA15f_3',
                },
                {
                  id: 'GRID_QA15f.QA15f:_4',
                  content: 'screening.global.response.GRID_QA15f.QA15f_4',
                },
                {
                  id: 'GRID_QA15f.QA15f:_5',
                  content: 'screening.global.response.GRID_QA15f.QA15f_5',
                },
              ],
            },
            {
              id: 'GRID_QA15f',
              promptId: 'GRID_QA15f:_2',
              question: 'screening.global.question.GRID_QA15f',
              prompt: 'screening.global.question.GRID_QA15f_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA15f.QA15f:_1',
                  content: 'screening.global.response.GRID_QA15f.QA15f_1',
                },
                {
                  id: 'GRID_QA15f.QA15f:_2',
                  content: 'screening.global.response.GRID_QA15f.QA15f_2',
                },
                {
                  id: 'GRID_QA15f.QA15f:_3',
                  content: 'screening.global.response.GRID_QA15f.QA15f_3',
                },
                {
                  id: 'GRID_QA15f.QA15f:_4',
                  content: 'screening.global.response.GRID_QA15f.QA15f_4',
                },
                {
                  id: 'GRID_QA15f.QA15f:_5',
                  content: 'screening.global.response.GRID_QA15f.QA15f_5',
                },
              ],
            },
            {
              id: 'GRID_QA15f',
              promptId: 'GRID_QA15f:_3',
              question: 'screening.global.question.GRID_QA15f',
              prompt: 'screening.global.question.GRID_QA15f_3',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA15f.QA15f:_1',
                  content: 'screening.global.response.GRID_QA15f.QA15f_1',
                },
                {
                  id: 'GRID_QA15f.QA15f:_2',
                  content: 'screening.global.response.GRID_QA15f.QA15f_2',
                },
                {
                  id: 'GRID_QA15f.QA15f:_3',
                  content: 'screening.global.response.GRID_QA15f.QA15f_3',
                },
                {
                  id: 'GRID_QA15f.QA15f:_4',
                  content: 'screening.global.response.GRID_QA15f.QA15f_4',
                },
                {
                  id: 'GRID_QA15f.QA15f:_5',
                  content: 'screening.global.response.GRID_QA15f.QA15f_5',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_1',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_1',
              timeframe: 'screening.global.question.timeframe.week',
              timeframeModal: 'timeframe.content.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_2',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_2',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_3',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_3',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_4',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_4',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_5',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_5',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_6',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_6',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_7',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_7',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_8',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_8',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_9',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_9',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A1',
              promptId: 'A1',
              question: 'screening.global.question.A1',
              timeframe: 'screening.global.question.timeframe.year',
              responses: [
                {
                  id: 'A1:_0',
                  content: 'screening.global.response.A1_0',
                },
                {
                  id: 'A1:_1',
                  content: 'screening.global.response.A1_1',
                },
                {
                  id: 'A1:_2',
                  content: 'screening.global.response.A1_2',
                },
                {
                  id: 'A1:_3',
                  content: 'screening.global.response.A1_3',
                },
                {
                  id: 'A1:_4',
                  content: 'screening.global.response.A1_4',
                },
                {
                  id: 'A1:_5',
                  content: 'screening.global.response.A1_5',
                },
                {
                  id: 'A1:_6',
                  content: 'screening.global.response.A1_6',
                },
                {
                  id: 'A1:_7',
                  content: 'screening.global.response.A1_7',
                },
                {
                  id: 'A1:_8',
                  content: 'screening.global.response.A1_8',
                },
                {
                  id: 'A1:_9',
                  content: 'screening.global.response.A1_9',
                },
                {
                  id: 'A1:_10',
                  content: 'screening.global.response.A1_10',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.global.section.sb.title',
          content: 'screening.global.section.sb.intro',
          background: '/bg-hero-sb-1.png',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'sb',
          questions: [
            {
              id: 'A18',
              promptId: 'A18:_3',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_3',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_11',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_11',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_4',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_4',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_7',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_7',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_17',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_17',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_6',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_6',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_5',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_5',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_1',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_14',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_14',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_2',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_19',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_19',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18_19._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18_19._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18_19._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18_19._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18_19._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.global.section.ls.title',
          content: 'screening.global.section.ls.intro',
          background: '/bg-hero-ls-1.png',
          backgroundStyles: 'bg-[-91px_center] md:bg-top lg:bg-center',
          id: 'ls',
          questions: [
            {
              id: 'A25',
              promptId: 'A25:_1',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_2',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_3',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_3',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_4',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_4',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_5',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_5',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A26',
              promptId: 'A26',
              question: 'screening.global.question.A26',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A26:_1',
                  content: 'screening.global.response.A26._1',
                },
                {
                  id: 'A26:_2',
                  content: 'screening.global.response.A26._2',
                },
              ],
            },
            {
              id: 'A27',
              promptId: 'A27',
              question: 'screening.global.question.A27',
              image: 'screening.global.question.image.A27',
              responses: [
                {
                  id: 'A27:_1',
                  content: 'screening.global.response.A27._1',
                },
                {
                  id: 'A27:_2',
                  content: 'screening.global.response.A27._2',
                },
                {
                  id: 'A27:_3',
                  content: 'screening.global.response.A27._3',
                },
                {
                  id: 'A27:_4',
                  content: 'screening.global.response.A27._4',
                },
                {
                  id: 'A27:_5',
                  content: 'screening.global.response.A27._5',
                },
              ],
            },
            {
              id: 'GRID_QA45',
              promptId: 'GRID_QA45:_2',
              question: 'screening.global.question.GRID_QA45',
              prompt: 'screening.global.question.GRID_QA45_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA45.QA45:_5',
                  content: 'screening.global.response.GRID_QA45.QA45_5',
                },
                {
                  id: 'GRID_QA45.QA45:_4',
                  content: 'screening.global.response.GRID_QA45.QA45_4',
                },
                {
                  id: 'GRID_QA45.QA45:_3',
                  content: 'screening.global.response.GRID_QA45.QA45_3',
                },
                {
                  id: 'GRID_QA45.QA45:_2',
                  content: 'screening.global.response.GRID_QA45.QA45_2',
                },
                {
                  id: 'GRID_QA45.QA45:_1',
                  content: 'screening.global.response.GRID_QA45.QA45_1',
                },
              ],
            },
          ],
        },
      ],
    },
  },
};

export default Config;
