import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  HeroLanding,
  Type,
} from '../../../../components';
import { LayoutLanding } from '../../../../layouts';
import getPath from '../../../../utils/path';

const getContent = (lang) => {
  const content = [
    'consent.content.1',
    'consent.content.2',
    'consent.content.3',
    'consent.content.4',
    'consent.content.5',
  ];

  if (lang === 'es-MX') {
    content.push('consent.content.7');
  }

  return content;
};

function Consent() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const content = getContent(lang);

  return (
    <LayoutLanding hero={<HeroLanding />}>
      <div className="flex flex-col gap-8 md:gap-8 max-w-[589px] mx-auto">
        <Type variant="lead-semibold" className="text-center lg:text-left">
          <Trans
            i18nKey="consent.lead"
            components={{
              Link: <Link to={getPath(lang, '/information')} className="text-axa-blue-500 underline" />,
            }}
          />
        </Type>
        <ol className="list-decimal list-inside">
          {content.map((item) => (
            <li key={item} className="mt-5 first:mt-0">{t(item)}</li>
          ))}
        </ol>
        <p>{t('consent.content.6')}</p>
        <div className="flex flex-col gap-4">
          <ButtonGroup className="!w-full md:flex-row">
            <Button type="link" to={getPath(lang, '/')} id="consent_refuse" className="w-full order-2 md:order-1 border border-gray-500 bg-gray-200">
              {t('consent.refuse')}
            </Button>
            <Button type="link" to={getPath(lang, '/assessment')} variant="primary" id="consent_accept" className="w-full order-1 md:order-2">
              {t('consent.cta')}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button type="link" to={getPath(lang, '/safeguarding')} variant="default" id="get_help_now" className="text-axa-blue-500">
              {t('consent.get_help_now')}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </LayoutLanding>
  );
}

export default Consent;
