import { post } from 'aws-amplify/api';

const API_NAME = import.meta.env.VITE_AWS_API_NAME;

const API = {
  async post(endpoint, options = {}) {
    const operation = post({
      apiName: options.apiName || API_NAME,
      path: endpoint,
      options,
    });

    const response = await operation.response;
    const body = await response.body.json();

    return body;
  },
};

export default API;
