import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './root';
import Landing from './landing';
import Consent from './consent/routes/_index';
import Information from './information/routes/_index';
import Safeguarding from './safeguarding/routes/_index';
import ScreeningIndex from './screening/routes/_index';
import ScreeningSection from './screening/routes/section';
import ScreeningQuestion from './screening/routes/question';
import ScreeningComplete, { screeningCompleteAction } from './screening/routes/complete';
import Results from './results/routes/_index';
import ResultsSubscore from './results/routes/subscore';
import Recommendations from './recommendations/routes/_index';
import RecommendationsGuide from './recommendations/routes/guide';

const router = createBrowserRouter([
  {
    path: '/',
    id: 'root',
    children: [
      {
        element: <Root />,
        children: [
          {
            path: '/:lang?',
            index: true,
            element: <Landing />,
            handle: {
              key: () => 'global_landing',
            },
          },
          {
            path: '/:lang?/consent',
            element: <Consent />,
            handle: {
              key: () => 'consent',
            },
          },
          {
            path: '/:lang?/information',
            element: <Information />,
            handle: {
              key: () => 'information_notice',
            },
          },
          {
            path: '/:lang?/safeguarding',
            element: <Safeguarding />,
            handle: {
              key: () => 'safeguarding',
            },
          },
          {
            path: '/:lang?/assessment',
            element: <ScreeningIndex />,
            handle: {
              key: () => 'start_assessment',
            },
          },
          {
            path: '/:lang?/assessment/section/:sectionIndex',
            element: <ScreeningSection />,
            handle: {
              key: ({ sectionIndex }) => `section_${sectionIndex}`,
            },
          },
          {
            path: '/:lang?/assessment/section/:sectionIndex/q/:questionIndex',
            element: <ScreeningQuestion />,
            handle: {
              key: ({ sectionIndex, questionIndex }) => `section_${sectionIndex}_question_${questionIndex}`,
            },
          },
          {
            path: '/:lang?/assessment/complete',
            element: <ScreeningComplete />,
            action: screeningCompleteAction,
            handle: {
              key: () => 'complete_assessment',
            },
          },
          {
            path: '/:lang?/results',
            element: <Results />,
            handle: {
              key: () => 'results',
            },
          },
          {
            path: '/:lang?/results/:subscore',
            element: <ResultsSubscore />,
            handle: {
              key: ({ subscore }) => `results_${subscore}`,
            },
          },
          {
            path: '/:lang?/recommendations',
            element: <Recommendations />,
            handle: {
              key: () => 'recommendations',
            },
          },
          {
            path: '/:lang?/recommendations/guide/:guide',
            element: <RecommendationsGuide />,
            handle: {
              key: ({ guide }) => `guide_${guide}`,
            },
          },
        ],
      },
    ],
  },
]);

function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router;
